// src/XpressSelectionPage.js

import React, { useState } from 'react';
import './css/xpressSelectionPage.css';
import SelectionComponent from './SelectionPage.js';
import Navbar from './Navbar.js';

const XpressSelectionPage = () => {
  const options = [
   'World news',
   'National news',
   'Hyperlocal news',
   'Politics',
   'Featured celebrity of the week',
   'Health and wellbeing',
   'Tech',
   'Sports',
   'Finance and business',
  ];
  const [selected, setSelected] = useState([]);
  const [showSelectionComponent, setShowSelectionComponent] = useState(false);

  // const handleSelect = (name) => {
  //   setSelected((prevSelected) =>
  //     prevSelected.includes(name)
  //       ? prevSelected.filter((item) => item !== name)
  //       : [...prevSelected, name]
  //   );
  // };
  const handleSelect = (name) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(name)) {
        return prevSelected.filter((item) => item !== name);
      } else if (prevSelected.length < 9) { // Max 3 selections
        return [...prevSelected, name];
      } else {
        return prevSelected; // No more than 3 selections
      }
    });
  };

  const handleConfirm = () => {
    // Set the state to show the selection component
    setShowSelectionComponent(true);
  };

  return (
    <>
    <Navbar/>
    <div className='xpress-main-container'>
      <div className="xpress-selection-container">
        <h2>Select Xpress</h2>
        {!showSelectionComponent ? (
          <>
            <div className="xpress-options">
              {options.map((option, index) => (
                <div 
                  key={index} 
                  className={`xpress-option ${selected.includes(option) ? 'selected' : ''}`} 
                  onClick={() => handleSelect(option)}
                >
                  {option}
                </div>
              ))}
            </div>
            {/* Submit Button */}
            {selected.length > 0 && (
              <button className="confirm-button glow-on" onClick={handleConfirm}>
                Confirm to Send
              </button>
            )}
          </>
        ) : (
          <SelectionComponent selectedOptions={selected} />
        )}
      </div>
    </div>
    </>
  );
};

export default XpressSelectionPage;


