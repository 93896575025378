// src/PrismaSelectionPage.js


import React, { useState } from 'react';
// import './css/PrismaSelectionPage.css';
import './css/PrismaSelectionPage.css';

import SelectionComponent from './SelectionPage.js';
import Navbar from './Navbar.js';

const PrismaSelectionPage = () => {
  const options = ['World News',
                  'National News',
                  'Hyperlocal news',
                  'Editors pick',
                  'Politics',
                  'Featured celebrity of the week',
                  'Health and Wellbeing',
                  'Tech',
                  'Sports',
                  'Finance and Business'
  ];
  const [selected, setSelected] = useState([]);
  const [showSelectionComponent, setShowSelectionComponent] = useState(false);


  const handleSelect = (name) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(name)) {
        return prevSelected.filter((item) => item !== name);
      } else if (prevSelected.length < 5) { // Max 3 selections
        return [...prevSelected, name];
      } else {
        return prevSelected; // No more than 3 selections
      }
    });
  };
  const handleConfirm = () => {
    setShowSelectionComponent(true);
  };

  return (
    <>
    <Navbar/>
    <div className='prisma-main-container'>
    <div className="prisma-selection-container">
      <h2>Select Prima </h2>
      {!showSelectionComponent ? (
        <>
          <div className="prisma-options">
            {options.map((option, index) => (
              <div 
                key={index} 
                className={`prisma-option ${selected.includes(option) ? 'selected' : ''}`} 
                onClick={() => handleSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {/* Submit Button */}
          {selected.length > 0 && (
            <button className="confirm-button glow-on" onClick={handleConfirm}>
              Confirm to Send
            </button>
          )}
        </>
      ) : (
        <SelectionComponent selectedOptions={selected} />
      )}
    </div>
  </div>
  </>
);
};

export default PrismaSelectionPage;
