import React, { useState } from 'react';
import './css/FAQAccordion.css';

const FAQAccordion = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-accordion">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div 
            className="faq-question" 
            onClick={() => toggleFAQ(index)}
          >
            {faq.question}
            <span className="faq-icon">
              {activeIndex === index ? '-' : '+'}
            </span>
          </div>
          <div
            className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
          >
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const faqs = [
  {
    question: "What is cloud computing?",
    answer: "Cloud computing is the on-demand delivery of IT resources over the Internet with pay-as-you-go pricing."
  },
  {
    question: "What is Amazon EC2?",
    answer: "Amazon EC2 is a web service that provides secure, resizable compute capacity in the cloud."
  },
  {
    question: "What is Amazon S3?",
    answer: "Amazon S3 is an object storage service that offers industry-leading scalability, data availability, security, and performance."
  }
];

const App = () => {
  return (
    < >
    
    <div className="app-container">
    <h2 className='heading'>Frequently Asked Questions </h2>
      <FAQAccordion faqs={faqs} />
    </div>
    </>
  );
};

export default App;
