import React from 'react'

function Pagenotfound() {
  return (
    <div>
      <h1>page not found</h1>
    </div>
  )
}

export default Pagenotfound
